<template>
  <div>
    <custom-breadcrumb
          pageTitle="Partner"
          :breadcrumb="[
            {
              text: 'Partners',
              to: 'partners'
            },
            {
              text: $t('forms.edit_model', {model: 'Partner'}),
              active: true,
            },
          ]"
    ></custom-breadcrumb>
    <component :is="recordData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="recordData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching school data
        </h4>
        <div class="alert-body">
          No user found with this id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            School List
          </b-link>
          for other schools.
        </div>
      </b-alert>
      <b-tabs v-model="tabIndex" v-if="recordData">
        <!-- Tab: Account -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Account</span>
          </template>
          <tab-account
            v-if="!logo_active"
            :record-data="recordData"
            @refetch-data="getData(recordData.id)"
            @toggle-logo-upload="toggleLogoUpload"
            class="mt-2 pt-75"
          />
          <tab-logo
            v-if="logo_active"
            @toggle-logo-upload="toggleLogoUpload"
            @refetch-data="getData(recordData.id)"
            :record-data="recordData"
            class="mt-2 pt-75"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Admin Licences</span>
          </template>
          <tab-admins
            :linked_users="linked_users" 
            :partner="recordData"
            @refetch-data="getData(recordData.id)"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Schools</span>
          </template>
          <tab-schools 
            :schools="schools"
            :partner="recordData"
            :countriesOptions="countriesOptions"
            @refetch-data="getData(recordData.id)"
          />
        </b-tab>
      </b-tabs>
    </component>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import TabAccount from './edit/TabAccount.vue'
import TabLogo from './edit/TabLogo.vue'
import TabSchools from './edit/TabSchools.vue'
import TabAdmins from './edit/TabAdmins.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

export default {
components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    TabLogo,
    TabAccount,
    TabSchools,
    TabAdmins,
    CustomBreadcrumb
  },
  data(){
    return {
      id: null,
      recordData: null,
      logo_active: false,
      linked_users:[],
      schools:[],
      tabIndex:null,

      countriesOptions:[]
    }
  },
  mounted(){
    this.id = router.currentRoute.params.id;
    this.getData(this.id)
    this.tabIndex = router.currentRoute.params.tab || 0;

  },
  methods: {
    toggleLogoUpload(){
      this.logo_active = !this.logo_active
    },
    refreshData(school){
      this.getData(school.id)
    },
     async getData(id){
        console.log("loading")
        let data = {
          'id': id
        }
        await this.$http.post('partners/fetch', data).then( response =>{
            console.log(response.data)
            if(response.data.partner.users_defaults){
              this.recordData = response.data.partner;
            } else {
              let rec_data = response.data.partner;
              rec_data['users_defaults'] = {
                'emailing':0,
                'lang': "English", //Revisar
                'max_children': 0,
                'welcomed': 0,
                'zero_emailing': 0
              }
              this.recordData = rec_data;

            }
            this.linked_users = response.data.linked_users;
            this.schools = response.data.schools;


            //Countries
            let countries = response.data.countries;
            let newCountriesOptions = []
            countries.forEach(x => {
            let capName = x.name.charAt(0).toUpperCase() + x.name.slice(1);
            newCountriesOptions.push(
                { 'value': x.code, 'label': capName }
            ) 
            });
            this.countriesOptions = newCountriesOptions;


        }).catch(error => {
            console.log("Err -> ", error)
        })
    },
  },
}
</script>

<style>

</style>