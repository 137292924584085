<template>
  <div>
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
        <h1>Upload image</h1>
      <div class="dropbox">
        <input type="file"  
          name="fileLogo"
          :disabled="isSaving" 
          @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
          accept="image/*" 
          class="input-file">
            <p v-if="isInitial">
              Drag your logo here to begin<br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading {{ fileCount }} files...
            </p>
      </div>
     
    </form>

    <!--SUCCESS-->
      <div v-if="isSuccess">
        <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Upload again</a>
        </p>
        <ul class="list-unstyled">
          <li v-for="item in uploadedFiles" :key="item.url">
            <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
          </li>
        </ul>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>

      <b-button
        variant="outline-secondary"
        class="mt-1"
        type="button"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$emit('toggle-logo-upload')"
      >
        {{ $t('forms.go_back') }}
      </b-button>
  </div>
</template>

<script>
import {
 BButton
} from 'bootstrap-vue'
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  components:{
    BButton
  },
  props: {
    recordData: {
      type: Object,
      required: true,
    },
  },
  data() {
      return {
          /* name: '',
          file: '',
          success: '' */

        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'photos'

      };
  },
  computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
  },
  methods: {
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
      },
     async save(formData) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;

          await this.$http.post('partners/edit/logo_upload', formData)
          .then(x => x.data)
          .then(x => {
            console.log(x, "---")
            this.uploadedFiles = [].concat(x);
            this.currentStatus = STATUS_SUCCESS;
            this.$emit('refetch-data');
          })
          .catch(err => {
            console.log("ERR", err.response)
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
       
      },
      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();
        formData.append('id', this.recordData.id);

        console.log("formData : ", formData)
        if (!fileList.length) return;

        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

        // save it
        this.save(formData);
      }
  },
  mounted() {
    this.reset();
  },
}
</script>


<style lang="scss">
  .dropbox {
    outline: 2px dashed #87bced; /* the dash box */
    outline-offset: -10px;
    background: #87bced30;
    color: #87bced;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #87bced70; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
